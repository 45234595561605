// /*global daum*/
import React, { useEffect,useState } from 'react'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
// import { listProducts } from '../actions/productActions'
import img1 from '../img/img.png'
import office from '../img/office.png'
import marker from '../img/marker.png'
import container from '../img/container.png'



const ProductScreen = () => {
//   useEffect(() => {
//      dispatch(listProducts())
//   },[dispatch])
const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    
  useEffect(() => {
      const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", resizeListener);
  });
  console.log("innerWidth", innerWidth);



  return (
    <>
        <div style={{width:"100%",height:"100%"}}>
          
           <div style={innerWidth > 450 ?
             {width:"100%",height:200,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
             :
             {width:"100%",height:140,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
            }>
              <img src={office} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover"}} />
              <h3 style={innerWidth > 450 ?{position:"absolute",fontSize:40, color:"white"}:{position:"absolute",fontSize:30, color:"white"}}>창고안내</h3>
           </div>
           <div style={innerWidth > 450 ?
             {width:"100%",height:50,display:"flex",justifyContent:"center",alignItems:"center",background:"#404041"}
             :
             {width:"100%",height:35,display:"flex",justifyContent:"center",alignItems:"center",background:"#404041"}
            }>
             <div style={{flex:0.1,height:"100%",borderRightStyle:"solid",color:"white"}}></div>
             <div style={{flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",maxWidth:1100}}>
              <div style={innerWidth > 450 ?
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",color:"white",fontSize:20,}
                :
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",color:"white",fontSize:16,}
              }>
                <a href="#con1" style={{color:"white"}}>지점소안내</a>
              </div>
              <div style={innerWidth > 450 ?
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",borderLeftStyle:"solid",borderRightStyle:"solid",color:"white",fontSize:20,}
                :
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",borderLeftStyle:"solid",borderRightStyle:"solid",color:"white",fontSize:16,}
              }>
                <a href="#con2" style={{color:"white"}}>창고가격표</a>
              </div>
              <div style={innerWidth > 450 ?
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",color:"white",fontSize:20,}
                :
                {flex:1,height:"100%",display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center",color:"white",fontSize:16,}

              }>
                <a href="#con3" style={{color:"white"}}>창고규격</a>
              </div>
             </div>

             <div style={{flex:0.1,height:"100%",borderLeftStyle:"solid",color:"white"}}></div>


           </div>
           <div style={innerWidth > 450 ?
             {width:"100%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",flexDirection:"column"}
             :
             {width:"90%",height:"100%",display:"flex",margin:"auto",flexDirection:"column"}

             } id="con1">
            
              <h3 style={innerWidth > 450 ?
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:25,marginTop:"5%"}
                :
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:17,marginTop:"10%",marginBottom:"3%"}
              }>
                <span style={innerWidth > 450 ?
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:48,display:"inline-block"}
                  :
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:30,display:"inline-block"}
                }>지점소안내</span>
              </h3>
              <div style={{width:"100%",height:"100%"}}>
                <Map
                  center={{ lat: 37.5850, lng: 127.14645 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.5850, lng: 127.14645 }}>
                    <div style={{color:"#000",width:"100%"}}>삼성물류보관소 본사</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 본사 : 경기도 구리시 토평동 613-23</p>
                </div>

                <Map
                  center={{ lat: 37.7040, lng: 127.05720 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.7040, lng: 127.05720 }}>
                    <div style={{color:"#000",width:"100%"}}>삼성물류보관소 의정부점</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 의정부점 : 경기도 의정부시 장암동 123</p>
                </div>

                <Map
                  center={{ lat: 37.6494, lng: 127.19077 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.6494, lng: 127.19077 }}>
                    <div style={{color:"#000",width:"100%"}}>삼성물류보관소 사능점</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 사능점 : 경기도 남양주시 진건읍 사릉로274</p>
                </div>
              </div>
              <h3 style={innerWidth > 450 ?
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:25,marginTop:"2%"}
                :
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:17,marginTop:"5%",marginBottom:"2%"}
              } id="con2">
                <span style={innerWidth > 450 ?
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:48,display:"inline-block"}
                  :
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:30,display:"inline-block"}
                  }>창고가격표</span>
              </h3>

              {innerWidth > 450 ?
              <div style={{width:"100%",height:"100%",marginTop:"2%",marginBottom:"2%"}}>
                <table style={{width:"100%",height:"100%"}}>
                  <thead>
                    <tr>
                      <th style={{width:"16%",background:"#404041",color:"white"}}></th>
                      <th style={{width:"16%",background:"#404041",color:"white"}}>20ft(1층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white"}}>40ft(1층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white"}}>20ft(2층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white"}}>40ft(2층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white"}}>20ft(3층)</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td  style={{width:"16%",background:"#404041",color:"white"}}>15일</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>120,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>240,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>100,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>200,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>80,000</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                    </tr>
                    <tr>
                      <td  style={{width:"16%",background:"#404041",color:"white"}}>1달</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>200,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>400,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>180,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>360,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>150,000</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                    </tr>
                    <tr>
                      <td  style={{width:"16%",background:"#404041",color:"white"}}>3달이상(장기DC가)</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 조정가능</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041"}}>-</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {/* <tr>
                      <td>1달</td>
                      <td>$180</td>
                    </tr> */}
                  </tfoot>
                </table>
              </div>
              :
              <div style={{width:"100%",height:"100%",marginTop:"2%",marginBottom:"2%"}}>
                <table style={{width:"100%",height:"100%"}}>
                  <thead>
                    <tr>
                      <th style={{width:"17%",background:"#404041",color:"white"}}></th>
                      <th style={{width:"16%",background:"#404041",color:"white",fontSize:12}}>20ft(1층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white",fontSize:12}}>40ft(1층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white",fontSize:12}}>20ft(2층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white",fontSize:12}}>40ft(2층)</th>
                      <th style={{width:"16%",background:"#404041",color:"white",fontSize:12}}>20ft(3층)</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td  style={{width:"17%",background:"#404041",color:"white",fontSize:12}}>15일</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>120,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>240,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>100,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>200,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>80,000</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                    </tr>
                    <tr>
                      <td  style={{width:"17%",background:"#404041",color:"white",fontSize:12}}>1달</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>200,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>400,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>180,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>360,000</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>150,000</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>

                    </tr>
                    <tr>
                      <td  style={{width:"17%",background:"#404041",color:"white",fontSize:12}}>3달이상<br/>(장기DC가)</td>
                      {/* <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 조정가능</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 조정가능</td> */}
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>가격 협의</td>
                      <td style={{width:"16%",background:"#e5e5e5",color:"#404041",fontSize:12}}>-</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    {/* <tr>
                      <td>1달</td>
                      <td>$180</td>
                    </tr> */}
                  </tfoot>
                </table>
              </div>
              }



              <h3 style={innerWidth > 450 ?
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:25,marginTop:"2%"}
                :
                {margin:0,height:"100%", width:"100%",borderBottomStyle:"solid",fontSize:17,marginTop:"2%",marginBottom:"3%"}
              } id="con3">
                <span style={innerWidth > 450 ?
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:48,display:"inline-block"}
                  :
                  {borderBottomStyle:"solid",borderBottomWidth:4,height:30,display:"inline-block"}
                }>창고규격(컨테이너)</span>
              </h3>
              <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",gap:"2%",marginTop:"2%",marginBottom:"10%"}}>
                <img src={container} alt="img1" style={
                  innerWidth > 450 ?
                  {width:"100%",height:"100%",flex:0.1}
                  :
                  {width:"5%",flex:1}
                } />
                <table style={{width:"100%",height:"100%",flex:1.4}}>
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td  style={innerWidth > 450 ?
                        {width:"20%",background:"#404041",color:"white"}
                        :
                        {width:"20%",background:"#404041",color:"white",fontSize:12}
                        }>약 4평</td>
                      <td style={innerWidth > 450 ?
                        {width:"80%",background:"#e5e5e5",color:"#404041"}
                        :
                        {width:"80%",background:"#e5e5e5",color:"#404041",fontSize:12}

                      }>2.4m X 2.6m X 6m (20ft)</td>
                    </tr>
                    <tr>
                      <td  style={innerWidth > 450 ?
                        {width:"20%",background:"#404041",color:"white"}
                        :
                        {width:"20%",background:"#404041",color:"white",fontSize:12}
                        }>약 9평</td>
                      <td style={innerWidth > 450 ?
                        {width:"80%",background:"#e5e5e5",color:"#404041"}
                        :
                        {width:"80%",background:"#e5e5e5",color:"#404041",fontSize:12}
                      }>4.8m X 2.6m X 6m (40ft)</td>
                    </tr>
                  </tbody>
                  <tfoot>
                  </tfoot>
                </table>
              </div>
           </div>
        </div>
        
    </>
  )
}

export default ProductScreen
