import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import NoticeScreen from './screens/NoticeScreen'
import WayScreen from './screens/WayScreen'
import ConsultScreen from './screens/ConsultScreen'
import NoticeList from './screens/NoticeList'
// import CartScreen from './screens/CartScreen'
// import LoginScreen from './screens/LoginScreen'
// import RegisterScreen from './screens/RegisterScreen'
// import ProfileScreen from './screens/ProfileScreen'
// import ShippingScreen from './screens/ShippingScreen'
// import PaymentScreen from './screens/PaymentScreen'
// import PlaceOrderScreen from './screens/PlaceOrderScreen'
// import OrderScreen from './screens/OrderScreen'



const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
            
          <Routes>
            {/* <Route path='/' component={HomeScreen} exact />
            <Route path='/product' component={ProductScreen} /> */}
            <Route path='/' element={<HomeScreen />} exact />
            <Route path='/notice' element={<NoticeScreen />} />
            <Route path='/product' element={<ProductScreen />} />
            <Route path='/way' element={<WayScreen />} />
            <Route path='/cousult' element={<ConsultScreen />} />
            <Route path='/list' element={<NoticeList />} />
          </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
