// /*global daum*/
import React, { useEffect, useState } from 'react'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
// import { listProducts } from '../actions/productActions'
import img1 from '../img/img.png'
import map2 from '../img/map2.png'
import marker from '../img/marker.png'
import con1 from '../img/con1.png'
import con2 from '../img/con2.png'
import con3 from '../img/con3.png'
import { Swiper, SwiperSlide } from "swiper/react"; // basic
import "swiper/css"; //basic
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation, Autoplay} from "swiper";
import 'swiper/css/autoplay'


const WayScreen = () => {
//   useEffect(() => {
//      dispatch(listProducts())
//   },[dispatch])
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    
  useEffect(() => {
      const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", resizeListener);
  });
  console.log("innerWidth", innerWidth);



  return (
    <>
        <div style={{width:"100%",height:"100%"}}>
          
           <div style={innerWidth > 450 ?
             {width:"100%",height:200,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
             :
             {width:"100%",height:140,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
            }>
              <img src={map2} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover"}} />
              <h3 style={innerWidth > 450 ?{position:"absolute",fontSize:40, color:"white"}:{position:"absolute",fontSize:30, color:"white"}}>오시는길</h3>
           </div>
     
           <div style={{width:"100%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",flexDirection:"column"}}>

            {
              innerWidth > 450 ?
             <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",margin:"5% 0",overflow:"hidden"}} className="wayImage">
              <img src={con1} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
              <div style={{width:"100%",height:"100%",flex:0.1}} />
              <img src={con2} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
              <div style={{width:"100%",height:"100%",flex:0.1}} />
              <img src={con3} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
             </div>

             :

             <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
     
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper1"
              style={{width:"90%",height:200,margin:"8% 5%",
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",}}
            >
              <SwiperSlide>
                <img src={con1} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={con1} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
              </SwiperSlide>
              <SwiperSlide>
                <img src={con1} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover",flex:1}} />
              </SwiperSlide>
            </Swiper>
            }
              
              <div style={
                innerWidth > 450 ?
                {width:"100%",height:"100%", marginBottom:"15%"}
                :
                {width:"90%",height:"100%",margin:"auto" , marginBottom:"15%",}

                }>
                <Map
                  center={{ lat: 37.5850, lng: 127.14645 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                    
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.5850, lng: 127.14645 }}>
                    <div style={{color:"#000",width:"100%"}}>삼성물류보관소 본사</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 본사 : 경기도 구리시 토평동 613-23</p>
                </div>

                <Map
                  center={{ lat: 37.7040, lng: 127.05720 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                    
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.7040, lng: 127.05720 }}>
                    <div style={{color:"#000",width:"100%"}}>삼성물류보관소 의정부점</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 의정부점 : 경기도 의정부시 장암동 123</p>
                </div>

                <Map
                  center={{ lat: 37.6494, lng: 127.19077 }}
                  style={innerWidth > 450 ?
                    { width: "100%", height: "360px", zIndex:99, marginTop:"2%" }
                    :
                    { width: "100%", height: "200px", zIndex:99, marginTop:"2%" }
                    
                  }
                  level={6}
                >
                  <MapMarker position={{ lat: 37.6494, lng: 127.19077 }}>
                    <div style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}>삼성물류보관소 사능점</div>
                  </MapMarker>
                </Map>
                <div style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}>
                  <img src={marker} alt="img1" style={{width:22,height:26,margin:"0 1%"}} />
                  <p style={innerWidth > 450 ?{marginTop:"1%",}:{marginTop:"3%",}}> 사능점 : 경기도 남양주시 진건읍 사릉로274</p>
                </div>
              </div>
              
            {innerWidth < 450 &&
              <div style={{width: "100%",height:140,display:"flex",justifyContent:"center",position:"relative",}}>
                <div style={{width: "100%",height:140,display:"flex",justifyContent:"center",lineHeight:"10px",flexDirection:"column",alignItems:"center",textAlign: "center",position:"relative",background:"#272d6c"}}>
                  <h3 style={{textAlign: "left",fontSize:20,fontWeight:"bold",color:"white"}}>온라인상담</h3>
                  <form
                    className="con-form gform"
                    action="https://script.google.com/macros/s/AKfycbyMwvvX-DXs01JFQERWQPd0TTxUvw5_ixMbji7gcIvdBn1LoyUitJ9NvnuwosvAmXi2/exec" 
                    method="POST" name="contactForm" id="contactForm"
                    // onSubmit={(e) => e.preventDefault()}
                    data-email="wjsdhtkd@gmail.com"
                  >
                    <div style={{width:"90%",display:"flex",flexDirection:"row",margin:"auto",gap:"1%"}}>
                      <input name="name" placeholder="성함" style={{width:"100%",height:26,fontSize:15,color:"black",flex:0.4,backgroundPosition:"100% 0",padding:0,borderRadius:10,paddingLeft:5}}  />
                      
                      <li className="phone" style={{listStyle:"none",width:"100%",height:30,justifyContent:"space-between",display:"flex",alignItems:"center",flex:1}}>
                        <select name="phone1" style={{width:"100%",height:"100%",flex:1,borderWidth:"1px",fontSize:15,borderRadius:10}}>
                          <option value="010">010</option>
                          <option value="070">070</option>
                          <option value="011">011</option>
                          <option value="016">016</option>
                          <option value="017">017</option>
                          <option value="018">018</option>
                          <option value="019">019</option>
                        </select>
                        <font style={{color:"white"}}>-</font>
                        <input type="text" name="phone2" className="required" required="" style={{width:"100%",height:30,flex:1,padding:0,margin:0,fontSize:15,backgroundPosition:"100% 0",padding:0,borderRadius:10}} />
                        <font style={{color:"white"}}>-</font>
                        <input type="text" name="phone3" className="required" required="" style={{width:"100%",height:30,flex:1,padding:0,margin:0,fontSize:15,backgroundPosition:"100% 0",padding:0,borderRadius:10}} />
                      </li>
                    </div>

                    


                    <div style={{display:"flex",flexDirection:"row",marginTop:"5%",height:40,width:"100%",position:"relative"}}>
                    
                      <button style={{flex:1,width:"100%",padding:0,margin:0,borderWidth:0,background:"#404041",color:"white",fontSize:20,fontWeight:"bold" }}>
                        신청하기
                      </button>
                    </div>
                    
                  </form>
                </div>
            </div>
            }


              
              
           </div>
        </div>
        
    </>
  )
}

export default WayScreen
