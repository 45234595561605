import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAgvnE78irrQTNG4Y9iFD0TZDkLa2mGt6U",
  authDomain: "samsung-507f4.firebaseapp.com",
  projectId: "samsung-507f4",
  storageBucket: "samsung-507f4.appspot.com",
  messagingSenderId: "22745083028",
  appId: "1:22745083028:web:f9574595f4da25ab655881"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebaseConfig 정보로 firebase 시작
firebase.initializeApp(firebaseConfig);

// firebase의 firestore 인스턴스를 변수에 저장
const firestore = firebase.firestore();

// 필요한 곳에서 사용할 수 있도록 내보내기
export { firestore };
