import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import Logo from '../logo.png'
import Quick from '../quick.png'
import Mail from '../mail.png'
import call from '../img/call_1.png'

const Header = () => {
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(false);
    const [modal, setModal] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll); //clean up
        };
    }, []);
    useEffect(() => {
        const resizeListener = () => {
        setInnerWidth(window.innerWidth);
        };
        window.addEventListener("resize", resizeListener);
    });
    console.log("innerWidth", innerWidth);
  

    const handleScroll = () => {
        // 스크롤이 Top에서 50px 이상 내려오면 true값을 useState에 넣어줌
        if(window.scrollY >= 50){
        setScroll(true);
        }else{
        // 스크롤이 50px 미만일경우 false를 넣어줌
        setScroll(false);
        }
        console.log(scroll)

    };

    const modalCon = () => {
        setModal(!modal)
        console.log(modal)
    }
        
    

  return (
    <>
    <div style={{width:"100%",position:"relative",justifyContent:"center",alignItems:"center",alignSelf:"center",textAlign:"center",zIndex:2}}>
        {
            innerWidth > 450 ?

        <div style={{maxWidth:"1100px",height:"100px",justifyContent:"center",alignItems:"center",textAlign:"center",margin:"auto",display:'flex',position:"relative"}}>
            <Link to="/">
                <div style={{height:"100px",justifyContent:"center",alignItems:"center",display:'flex'}}>
                    <img src={Logo} style={{justifyContent:"center",alignItems:"center",alignSelf:"center",height:72,width:100}} />
                </div>
            </Link>
            <div  style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,display:"flex",flex:1}} />
            <Link to="/notice" style={{display:"flex",flex:1,listStyle:"unset"}}>
                <div style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"#23282a",display:"flex",alignItems:"center"}}>
                    <a style={{fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"#23282a"}}>공지사항</a>
                </div>
            </Link>
            <Link to="/product" style={{display:"flex",flex:1}}>
                <div style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,color:"#23282a",letterSpacing:-.8,display:"flex",alignItems:"center"}}>창고안내</div>
            </Link>
            <Link to="/way" style={{display:"flex",flex:1}}>
                <div style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,color:"#23282a",letterSpacing:-.8,display:"flex",alignItems:"center"}}>오시는길</div>
            </Link>
            <Link to="/cousult" style={{display:"flex",flex:1}}>
                <div style={{height:"100px",width:"100%",border:"0",background:"#272d6c",color:"white",fontSize:20,padding:0,letterSpacing:-.8,display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img src={Mail} style={{paddingRight:5}} />
                    상담창구
                </div>
            </Link>
                <img src={Quick} 
                    style={
                    scroll ?
                    {position:"fixed",width:150,height:150,
                    left:'85.5%',
                    zIndex:40,
                    top:5}
                    :
                    innerWidth < 1400 ?
                    {position:"absolute",width:150,height:150,
                    // left:'102%',
                    right:0,
                    zIndex:40,

                    top:105}
                    :
                    {position:"absolute",width:150,height:150,
                    left:'102%',
                    zIndex:40,

                    // right:0,
                    top:5}
                } />
        </div>
        :
        <div style={{width:"100%",height:"70px",justifyContent:"start",alignItems:"center",textAlign:"center",margin:"auto",display:'flex',position:"relative"}}>
            <div style={{height:"100%",width:"100%",border:"0",background:"white",fontSize:20,display:"flex",flex:0.1}} />

            <Link to="/" style={{flex:1}}>
                <div style={{height:"100%",justifyContent:"center",alignItems:"center",display:'flex',flex:1}}>
                    <img src={Logo} style={{justifyContent:"center",alignItems:"center",alignSelf:"center",height:50,width:70,marginLeft:"1%"}} />
                </div>
            </Link>
            <div style={{height:"100%",width:"100%",border:"0",background:"white",fontSize:20,display:"flex",flex:1}} />
            <div style={{height:"100%",width:"100%",border:"0",background:"white",fontSize:20,display:"flex",flex:1}} />
            <div style={{height:"100%",width:142,border:"0",background:"white",fontSize:20,display:"flex",display:"flex",justifyContent:"center"}} >
                <a href="tel:031-566-2288" style={{height:"100%",width:"100%",border:"0",backgroundColor:"#3f3f40",fontSize:20,display:"flex",flex:1,justifyContent:"center"}}>
                    <img src={call} style={{justifyContent:"center",alignItems:"center",alignSelf:"center",height:26,width:26}} />
                </a>
                <div style={{height:"100%",width:"100%",border:"0",background:"#272d6c",display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}
                onClick={()=> modalCon()}
                >
                    <div id="line-wrapper">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                    </div>
                </div>
            </div>
            
            {/* 
            <Link to="/notice" style={{display:"flex",flex:1,listStyle:"unset"}}>
                <button style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"#23282a"}}>
                    <a  style={{fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"#23282a"}}>공지사항</a>
                </button>
            </Link>
            <Link to="/product" style={{display:"flex",flex:1}}>
                <button style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,color:"#23282a",letterSpacing:-.8}}>창고안내</button>
            </Link>
            <Link to="/way" style={{display:"flex",flex:1}}>
                <button style={{height:"100px",width:"100%",border:"0",background:"white",fontSize:20,padding:0,color:"#23282a",letterSpacing:-.8}}>오시는길</button>
            </Link>
            <Link to="/cousult" style={{display:"flex",flex:1}}>
                <button style={{height:"100px",width:"100%",border:"0",background:"#272d6c",color:"white",fontSize:20,padding:0,letterSpacing:-.8}}>
                    <img src={Mail} style={{paddingRight:5}} />
                    상담창구
                </button>
            </Link>
                <img src={Quick} 
                    style={
                    scroll ?
                    {position:"fixed",width:150,height:150,
                    left:'85.5%',
                    zIndex:40,
                    top:5}
                    :
                    innerWidth < 1400 ?
                    {position:"absolute",width:150,height:150,
                    // left:'102%',
                    right:0,
                    zIndex:40,

                    top:105}
                    :
                    {position:"absolute",width:150,height:150,
                    left:'102%',
                    zIndex:40,

                    // right:0,
                    top:5}
                } /> */}

                
                
            </div>
            
            

            }
            {
                modal &&
            <div style={{width:"100%",height:"100%",background:"white",}}>
                <Link to="/notice"  style={{display:"flex",flex:1,listStyle:"unset"}} onClick={()=> modalCon()}>
                    <div style={{height:"55px",width:"100%",border:"0",background:"#272d6c",fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"#23282a",display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <a style={{fontSize:20,padding:0,fontWeight:400,letterSpacing:-.8,color:"white"}}>공지사항</a>
                    </div>
                </Link>
                <Link to="/product" style={{display:"flex",flex:1}}onClick={()=> modalCon()}>
                    <div style={{height:"55px",width:"100%",border:"0",background:"#272d6c",fontSize:20,padding:0,color:"white",letterSpacing:-.8,display:"flex",alignItems:"center",justifyContent:"center"}}>창고안내</div>
                </Link>
                <Link to="/way" style={{display:"flex",flex:1}}onClick={()=> modalCon()}>
                    <div style={{height:"55px",width:"100%",border:"0",background:"#272d6c",fontSize:20,padding:0,color:"white",letterSpacing:-.8,display:"flex",alignItems:"center",justifyContent:"center"}}>오시는길</div>
                </Link>
                <Link to="/cousult" style={{display:"flex",flex:1}}onClick={()=> modalCon()}>
                    <div style={{height:"55px",width:"100%",border:"0",background:"#272d6c",color:"white",fontSize:20,padding:0,letterSpacing:-.8,display:"flex",alignItems:"center",justifyContent:"center"}}>
                        상담창구
                    </div>
                </Link>
            </div>
            }


        </div>
    </>
  )
}

export default Header
