// /*global daum*/
import React, { useEffect, useState } from 'react'
import consult from '../img/consult.png'
import { firestore } from "../firebase";
import { useNavigate } from 'react-router-dom';
import campaign from '../img/campaign.png'
import { collection, getDocs, addDoc, updateDoc, doc, Timestamp } from "firebase/firestore";

const ConsultScreen = () => {
  let bucket_data = [];
  const db = firestore;
  const id = window.location.search.substring(7)
  var totals =  db.collection('product');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [mainData, setMainData] = useState(0)
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fixed, setFixed] = useState(false);
  const [sid, setSid] = useState(0);
  const [date, setDate] = useState("")
  const [modal, setModal] = useState(false);

useEffect(() => {
    
 
  console.log(id)
  console.log(totals,"토탈즈")

  db.collection('consult').get().then((e)=>{
    console.log(e.docs,"결과")
    e.forEach((doc) => {
      console.log(doc,"docdco");
      // 도큐먼트 데이터 가져오기
      console.log(doc.data(),"도큐먼트 데이타");
      console.log(doc.data().date.toDate(),"날짜")
          // 도큐먼트 id 가져오기
      console.log(doc.id,"도큐멘트 아이디");
      if (doc.exists) {
          let dateFormat = doc.data().date.toDate().getFullYear() + "." + doc.data().date.toDate().getMonth()  + "." + doc.data().date.toDate().getDate()
            console.log(doc.data().date.toDate().getFullYear(),"새로운 날짜");

          bucket_data = [...bucket_data, {...doc.data(), id: doc.id,  date:dateFormat  }];
          console.log(bucket_data,"들어갈 데이터")
          // let result = bucket_data.filter((v, i) => bucket_data.indexOf(v) === i);
          let result = bucket_data.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
          console.log(result,"리절트 확인");

            // setMainData(result)
          const arrUnique = bucket_data.filter((character, idx, arr)=>{
              return arr.findIndex((item) => item.id === character.id && item.date === character.date) === idx
          })
            setMainData(arrUnique)
          console.log(mainData,"덮어씌워지겠지?")
        }
          

        //  if(bucket_data) {
        //   setMainData(bucket_data)
        //   console.log(bucket_data,"버켓데이터")
        // }
      })
      
    })
  },[])






  const handleDetailPost = ({ item }) => {
    navigate('/list', {
      state: {
        id: `${item.id}`,
        sid: `${item.sid}`,
        title: `${item.title}`,
        date: `${item.date}`,
        description: `${item.description}`,
        name: `${item.name}`
      },
    });
  };
  


    
  useEffect(() => {
      const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", resizeListener);
  });
  console.log("innerWidth", innerWidth);


   const modalCon = () => {
        setModal(!modal)
        console.log(modal)
    }


  const usersCollectionRef = collection(db, "consult");






  const createUsers = async () =>{
    // addDoc을 이용해서 내가 원하는 collection에 내가 원하는 key로 값을 추가한다.
    await addDoc(usersCollectionRef, 
      {
        title: title, 
        description: description,
        fixed: false,
        sid: mainData[0].sid + 1,
        date: new Date(),
        name: name,
      }
    );
    window.location.reload();
  }



  return (
    <>
        <div style={{width:"100%",height:"100%"}}>
          
           <div style={
             innerWidth > 450 ?
             {width:"100%",height:200,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
             :
             {width:"100%",height:140,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
            
             }>
              <img src={consult} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover"}} />
              <h3 style={innerWidth > 450 ?{position:"absolute",fontSize:40, color:"white"}:{position:"absolute",fontSize:30, color:"white"}}>상담창구</h3>
           </div>
           
     
           <div style={
             innerWidth > 450 ?
             {width:"100%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",flexDirection:"column"}
             :
             {width:"90%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",flexDirection:"column"}
             }>
             
            
              
              <div style={{width:"100%",height:"100%", marginTop:"10%",textAlign:"center",background:"#272d6c"}}>
                <div style={{width:"100%",display:"flex",flexDirection:"row",height:40}}>
                  <p style={innerWidth > 450 ?
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0}
                    :
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0,fontSize:12}
                  }>번호</p>
                  <p style={innerWidth > 450 ?
                    {display:"flex",flex:3,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0}
                    :
                    {display:"flex",flex:5,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0,fontSize:12}
                  }>제목</p>
                  <p style={innerWidth > 450 ?
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0}
                    :
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0,fontSize:12}}>작성자</p>
                  <p style={innerWidth > 450 ?
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0}
                    :
                    {display:"flex",flex:1,justifyContent:"center",alignItems:"center",color:"white",fontWeight:"800",margin:0,fontSize:12}}>작성일</p>
                </div>
                
                {
                  mainData ? mainData.map((index,id, idx, item) => {
                  return (
                    (mainData[id].fixed === true &&
                    <div key={id} style={{width:"100%",display:"flex",flexDirection:"row",background:"#f5f6fa",borderRightWidth:0,borderLeftWidth:0,borderTop:"solid",borderTopColor:"#efefef"}}>
                      {/* <p style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>{mainData[id].id}</p> */}
                      {/* <p style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>{mainData[id].sid}</p> */}
                      <div style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center",margin:0}}>
                        <img src={campaign} alt="img1" style={{width:25,height:25,objectFit:"cover",background:"#ed6478",justifyContent:"center",alignItems:"center",margin:0}} />
                      </div>
                      {/* <Link to="/list" style={{display:"flex",flex:3,listStyle:"unset",color:"black"}}> */}
                      <p  onClick={()=> handleDetailPost({item: mainData[id]})} 
                        style={innerWidth > 450 ?
                          {display:"flex",flex:3,justifyContent:"start",alignItems:"center",fontWeight:"800"}
                          :
                          {display:"flex",flex:5,justifyContent:"start",alignItems:"center",fontWeight:"800",fontSize:13}
                        }>
                          {mainData[id].title}
                      </p>
                      {/* </Link> */}
                      {/* <p style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>{mainData[id].description}</p> */}
                      <p style={
                        innerWidth > 450 ?
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center"}
                        :
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center",fontSize:10}
                      }>{mainData[id].name}</p>
                      <p style={innerWidth > 450 ?
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center"}
                        :
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center",fontSize:10}}>{mainData[id].date}</p>
                    </div>
                    )
                    ||
                    (mainData[id].fixed === false &&
                    <div key={id} style={{width:"100%",display:"flex",flexDirection:"row",background:"white",borderRightWidth:0,borderLeftWidth:0,borderTop:"solid",borderTopColor:"#efefef"}}>
                      {/* <p style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>{mainData[id].id}</p> */}
                      <p style={
                        innerWidth > 450 ?
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center"}
                        :
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center",fontSize:10}
                      }>{mainData[id].sid+1}</p>
                      {/* <Link to="/list" style={{display:"flex",flex:3,listStyle:"unset",color:"black"}}> */}
                      <p  onClick={()=> handleDetailPost({item: mainData[id]})} 
                        style={innerWidth > 450 ?
                          {display:"flex",flex:3,justifyContent:"start",alignItems:"center",fontWeight:"800"}
                          :
                          {display:"flex",flex:5,justifyContent:"start",alignItems:"center",fontWeight:"800",fontSize:13}}>{mainData[id].title}</p>
                      {/* </Link> */}
                      {/* <p style={{display:"flex",flex:1,justifyContent:"center",alignItems:"center"}}>{mainData[id].description}</p> */}
                      <p style={innerWidth > 450 ?
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center"}
                        :
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center",fontSize:10}}>{mainData[id].name}</p>
                      <p style={innerWidth > 450 ?
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center"}
                        :
                        {display:"flex",flex:1,justifyContent:"center",alignItems:"center",fontSize:10}
                      }>{mainData[id].date}</p>
                    </div>
                    )
                    
                  );
                }
                
                )
                
                :
                <p style={{color:"white"}}>loading</p>
                }
               
               
             
 

              </div>
              
                 <button  onClick={()=> modalCon()} style={innerWidth > 450 ?
                   {width:100,height:50,marginBottom:"10%",marginTop:"1%",right:0,position:"relative",marginLeft:"auto"}
                   :
                   {width:70,height:30,marginBottom:"10%",marginTop:"5%",right:0,position:"relative",marginLeft:"auto"}

                   }>글쓰기</button>


              
              
          </div>
          {modal &&
            <div style={{width:"100%",height:"100%",position:"absolute",left:0,top:0,justifyContent:"center",alignItems:"center",display:"flex"}}>
              <div style={{width:"100%",height:"100%",background:"black",position:"absolute",left:0,top:0,opacity:0.3,zIndex:90}} />
              <div 
                style={innerWidth > 450 ?
                  {width:"70%",height:"80%",justifyContent:"center",alignItems:"center",background:"white",opacity:1,zIndex:99,flexDirection:"column",display:"flex",position:"relative",}
                  :
                  {width:"90%",height:"70%",justifyContent:"center",alignItems:"center",background:"white",opacity:1,zIndex:99,flexDirection:"column",display:"flex",position:"relative",}
                  
                  }>
                <button  onClick={()=> modalCon()} style={{width:50,height:30,position:"absolute",top:10,right:10}}>닫기</button>
                <div style={innerWidth > 450 ?
                  {width:"80%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}
                  :
                  {width:"90%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex",flexDirection:"column"}

                  }>
                  <div style={{width:"100%",display:"flex",flexDirection:"row"}}>
                    <p style={innerWidth > 450 ?
                      {height:50,flex:1}
                      :
                      {height:40,flex:1.2}
                    } >제목</p>
                    <input style={innerWidth > 450 ?
                      {height:50,flex:5}
                      :
                      {height:40,flex:5}
                      } type="text" placeholder='제목...' onChange={(event)=> {setTitle(event.target.value)}}/>
                  </div>
                  <div style={{width:"100%",display:"flex",flexDirection:"row"}}>
                    <p style={innerWidth > 450 ?
                      {height:50,flex:1}
                      :
                      {height:40,flex:1.2}} >작성자</p>
                    <input style={innerWidth > 450 ?
                      {height:50,flex:5}
                      :
                      {height:40,flex:5}} type="text" placeholder='작성자...' onChange={(event)=> {setName(event.target.value)}}/>
                  </div>
                  <div style={{width:"100%",display:"flex",flexDirection:"row"}}>
                    <p style={innerWidth > 450 ?
                      {height:50,flex:1}
                      :
                      {height:40,flex:1.2}} >내용</p>
                    <textarea style={innerWidth > 450 ?
                      {flex:5,heiht:"100%",minHeight:250}
                      :
                      {flex:5,heiht:"100%",minHeight:200}
                      }  type="text" placeholder='설명...' onChange={(event)=> {setDescription(event.target.value)}}/>
                  </div>
                </div>
                <button style={innerWidth > 450 ?
                  {width:100,height:30,right:"3%",bottom:"5%",position:"relative",marginLeft:"auto"}
                  :
                  {width:100,height:30,right:"3%",bottom:"10%",position:"relative",marginLeft:"auto"}
                  } onClick={createUsers}>글 쓰기</button>
                
              </div>
            </div>
          }




          {innerWidth < 450 &&
              <div style={{width: "100%",height:140,display:"flex",justifyContent:"center",position:"relative",}}>
                <div style={{width: "100%",height:140,display:"flex",justifyContent:"center",lineHeight:"10px",flexDirection:"column",alignItems:"center",textAlign: "center",position:"relative",background:"#272d6c"}}>
                  <h3 style={{textAlign: "left",fontSize:20,fontWeight:"bold",color:"white"}}>온라인상담</h3>
                  <form
                    className="con-form gform"
                    action="https://script.google.com/macros/s/AKfycbyMwvvX-DXs01JFQERWQPd0TTxUvw5_ixMbji7gcIvdBn1LoyUitJ9NvnuwosvAmXi2/exec" 
                    method="post" name="contactForm" id="contactForm"
                    // onSubmit={(e) => e.preventDefault()}
                    data-email="wjsdhtkd@gmail.com"
                  >
                    <div style={{width:"90%",display:"flex",flexDirection:"row",margin:"auto",gap:"1%"}}>
                      <input name="name" placeholder="성함" style={{width:"100%",height:26,fontSize:15,color:"black",flex:0.4,backgroundPosition:"100% 0",padding:0,borderRadius:10,paddingLeft:5}}  />
                      
                      <li className="phone" style={{listStyle:"none",width:"100%",height:30,justifyContent:"space-between",display:"flex",alignItems:"center",flex:1}}>
                        <select name="phone1" style={{width:"100%",height:"100%",flex:1,borderWidth:"1px",fontSize:15,borderRadius:10}}>
                          <option value="010">010</option>
                          <option value="070">070</option>
                          <option value="011">011</option>
                          <option value="016">016</option>
                          <option value="017">017</option>
                          <option value="018">018</option>
                          <option value="019">019</option>
                        </select>
                        <font style={{color:"white"}}>-</font>
                        <input type="text" name="phone2" className="required" required="" style={{width:"100%",height:30,flex:1,padding:0,margin:0,fontSize:15,backgroundPosition:"100% 0",padding:0,borderRadius:10}} />
                        <font style={{color:"white"}}>-</font>
                        <input type="text" name="phone3" className="required" required="" style={{width:"100%",height:30,flex:1,padding:0,margin:0,fontSize:15,backgroundPosition:"100% 0",padding:0,borderRadius:10}} />
                      </li>
                    </div>

                    


                    <div style={{display:"flex",flexDirection:"row",marginTop:"5%",height:40,width:"100%",position:"relative"}}>
                    
                      <button style={{flex:1,width:"100%",padding:0,margin:0,borderWidth:0,background:"#404041",color:"white",fontSize:20,fontWeight:"bold" }}>
                        신청하기
                      </button>
                    </div>
                    
                  </form>
                </div>
            </div>
            }
           
        </div>
        
    </>
  )
}


export default ConsultScreen
