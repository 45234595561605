// /*global daum*/
import React, { useEffect, useState } from 'react'
import { Map, MapMarker } from 'react-kakao-maps-sdk'
// import { listProducts } from '../actions/productActions'
import consult from '../img/consult.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import notice from '../img/notice.png'


const NoticeList = () => {
//   useEffect(() => {
//      dispatch(listProducts())
//   },[dispatch])
const [innerWidth, setInnerWidth] = useState(window.innerWidth);
const location = useLocation();
const userInfo = { ...location.state };
const navigate = useNavigate();


 useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);



console.log(userInfo,"유저인포")
    
  useEffect(() => {
      const resizeListener = () => {
      setInnerWidth(window.innerWidth);
      };
      window.addEventListener("resize", resizeListener);
  });
  console.log("innerWidth", innerWidth);





  return (
    <>
        <div style={{width:"100%",height:"100%"}}>
          
           <div style={
             innerWidth > 450 ?
             {width:"100%",height:200,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
             :
             {width:"100%",height:140,display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}
            
             }>
              <img src={notice} alt="img1" style={{width:"100%",height:"100%",objectFit:"cover"}} />
              <h3 style={innerWidth > 450 ?{position:"absolute",fontSize:40, color:"white"}:{position:"absolute",fontSize:30, color:"white"}}>공지사항</h3>
           </div>
     
           <div style={ innerWidth > 450 ?
               {width:"100%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",flexDirection:"column"}
               :
               {width:"90%",height:"100%",display:"flex",maxWidth:1100,margin:"auto",marginTop:"5%",flexDirection:"column"}
            }>
               <div style={{width:"100%",height:70,display:"flex",flexDirection:"row",borderTop:"solid 2px",marginTop:"3%",background:"#fafafa",alignItems:"center"}}>
                   <p style={innerWidth > 450 ?
                       {flex:8,paddingLeft:"3%",fontSize:20,fontWeight:"800"}
                       :
                       {flex:8,paddingLeft:"3%",fontSize:15,fontWeight:"800"}

                    }>{userInfo.title}</p>
                   <p style={innerWidth > 450 ?
                       {flex:1,justifyContent:"center",alignItems:"center"}
                       :
                       {flex:2,justifyContent:"center",alignItems:"center",marginRight:10}

                    }>
                       {userInfo.name
                       ? 
                        userInfo.name
                        :
                        "Admin"
                        }
                        </p>
                   <p style={{flex:1,justifyContent:"center",alignItems:"center"}}>{userInfo.date}</p>
               </div>
               <div style={innerWidth > 450 ?
                   {width:"100%",height:"100%",minHeight:500,display:"flex",flexDirection:"row",background:"white"}
                   :
                   {width:"100%",height:"100%",minHeight:300,display:"flex",flexDirection:"row",background:"white"}
                }>
                    {/* <div style={{display:"flex",flex:1,justifyContent:"flex-start",alignItems:"flex-start",paddingBlock:"2%",paddingInline:"3%",margin:0,color:"#9a9a9a"}}>
               
                        {userInfo.description}
                    </div> */}
                    {/* {userInfo.description.split("\\n").map((data) => { //this.props.data.content: 내용
                        return (
                        <span>
                            {data}
                            <br />
                        </span>
                        );
                    })} */}
                    {userInfo.description && (
                        <p style={{justifyContent:"flex-start",alignItems:"flex-start",paddingBlock:"2%",paddingInline:"3%",margin:0,color:"#9a9a9a"}}>
                            {
                                userInfo.description.split("\\n").map((line) => {
                                    return (
                                        <span>
                                            {line}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    )}
               </div>

               {/* <p  onClick={()=> handleDetailPost({item: userInfo[(id+1)]})} style={{display:"flex",flex:3,justifyContent:"start",alignItems:"center",fontWeight:"800"}}></p> */}


               <div style={{width:"100%",height:"100%",minHeight:200,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                   {userInfo.name
                       ? 
                        <Link to="/cousult" style={{width:"100%",maxWidth:150,height:50,listStyle:"unset",color:"black",background:"#272d6c",justifyContent:"center",alignItems:"center",borderRadius:7}}>
                        <p style={{textAlign:"center",color:"white",fontSize:17,fontWeight:"800",margin:0,lineHeight:3}}>목록</p>
                        </Link>
                        :
                        <Link to="/notice" style={{width:"100%",maxWidth:150,height:50,listStyle:"unset",color:"black",background:"#272d6c",justifyContent:"center",alignItems:"center",borderRadius:7}}>
                            <p style={{textAlign:"center",color:"white",fontSize:17,fontWeight:"800",margin:0,lineHeight:3}}>목록</p>
                        </Link>
                    } 
               </div>
              
             
              
            


              
              
           </div>
        </div>
        
    </>
  )
}

export default NoticeList
